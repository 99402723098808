import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { PoPageLoginLiterals } from '@po-ui/ng-templates/lib/components/po-page-login/interfaces/po-page-login-literals.interface';
import { PoPageLogin } from '@po-ui/ng-templates';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'ses-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loading: boolean;

  public readonly literals: PoPageLoginLiterals = {
    title: '',

    loginPlaceholder: 'Insira seu Usuário',
    passwordPlaceholder: 'Insira sua Senha',
    rememberUser: 'Manter logado',
    submitLabel: 'Entrar',
  };

  constructor(
    private loginService: LoginService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit() {}

  login(form: PoPageLogin) {
    this.loading = true;

    this.loginService
      .login({
        userName: form.login,
        password: form.password,
      })
      .subscribe(
        () => {
          this.router.navigate(['/']);
        },
        (error) => {
          if (error.status === 400) {
            this.loading = false;
          }
        }
      );
  }
}
