<div class="container">
  <po-navbar
    id="navbar"
    p-shadow="true"
    p-logo="/assets/images/logo-ses-white.png"
    [p-icon-actions]="botoes"
  ></po-navbar>
  <div class="po-wrapper">
    <po-menu [p-menus]="menus"></po-menu>
    <router-outlet></router-outlet>
  </div>
  <div class="floating-title">
    <img src="/assets/images/Logo-agenda-2.png" />
  </div>
</div>
