import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  login(credentials: { userName: string; password: string }) {
    return this.http
      .post('/auth/login', credentials)
      .pipe(
        tap((response: { success: boolean; data: string }) =>
          this.authService.setToken(response.data)
        )
      );
  }
}
