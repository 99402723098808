<div class="login-container">
  <div class="login-left">
    <img
      src="/assets/images/Logo-ses-novo.png"
      alt="Logo SES"
      class="logo-ses"
    />
    <img
      src="/assets/images/logo-agenda.png"
      alt="Logo Agenda Medicamentos"
      class="logo-agenda"
    />
    <p class="sub-text">
      Superintendência de Assistência Farmacêutica e Insumos Estratégicos
    </p>
  </div>
  <div class="login-right">
    <po-page-login
      p-logo="/assets/images/icone-usuario.png"
      [p-literals]="literals"
      [p-authentication-type]="'Bearer'"
      [p-loading]="loading"
      (p-login-submit)="login($event)"
    ></po-page-login>
  </div>
</div>
