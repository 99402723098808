import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/containers/login/login.component';
import { AuthGuard } from './core/services/auth-guard.service';
import { MainContainerComponent } from './core/main-container/main-container.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'teste-covid',
    loadChildren: () =>
      import('./teste-covid/teste-covid.module').then(
        (m) => m.TesteCovidModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainContainerComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'guiche',
        loadChildren: () =>
          import('./guiche/guiche.module').then((m) => m.GuicheModule),
      },
      {
        path: 'conta',
        loadChildren: () =>
          import('./conta/conta.module').then((m) => m.ContaModule),
      },
      {
        path: 'paciente',
        loadChildren: () =>
          import('./paciente/paciente.module').then((m) => m.PacienteModule),
      },
      {
        path: 'responsavel',
        loadChildren: () =>
          import('./responsavel/responsavel.module').then(
            (m) => m.ResponsavelModule
          ),
      },
      {
        path: 'unidade',
        loadChildren: () =>
          import('./unidade/unidade.module').then((m) => m.UnidadeModule),
      },
      {
        path: 'feriado',
        loadChildren: () =>
          import('./feriado/feriado.module').then((m) => m.FeriadoModule),
      },
      {
        path: 'tipo',
        loadChildren: () =>
          import('./tipo/tipo.module').then((m) => m.TipoModule),
      },
      {
        path: 'configuracoes',
        loadChildren: () =>
          import('./configuracoes/configuracoes.module').then(
            (m) => m.ConfiguracoesModule
          ),
      },
      {
        path: 'bloqueio',
        loadChildren: () =>
          import('./bloqueio/bloqueio.module').then((m) => m.BloqueioModule),
      },
      {
        path: 'agenda',
        loadChildren: () =>
          import('./agenda/agenda.module').then((m) => m.AgendaModule),
      },
      {
        path: 'atendimento',
        loadChildren: () =>
          import('./atendimento/atendimento.module').then(
            (m) => m.AtendimentoModule
          ),
      },
      {
        path: 'usuario',
        loadChildren: () =>
          import('./usuario/usuario.module').then((m) => m.UsuarioModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
