import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  PoDialogService,
  PoNotificationService,
  PoToasterOrientation,
} from '@po-ui/ng-components';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    protected notification: PoNotificationService,
    protected dialog: PoDialogService
  ) {}

  public err: any;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {},
        (error: HttpErrorResponse) => {
          this.processErrorResponse(error);
        }
      )
    );
  }

  processErrorResponse(response: HttpErrorResponse) {
    const errorResponse =
      response.status !== 0
        ? response.error
        : {
            code: 0,
            message: 'Servidor não está respondendo.',
            detailedMessage: response.message,
          };

    // not show the notification when has NoError parameter on header of request.
    if (errorResponse && errorResponse.errors) {
      errorResponse.errors =
        typeof errorResponse.errors === 'object'
          ? Object.keys(errorResponse.errors).map(
              key => errorResponse.errors[key]
            )
          : errorResponse.errors;

      this.showErrorNotification(errorResponse, response.status);
    }
  }

  protected showErrorNotification(errorResponse: any, status: number) {
    const notificationAction = this.generateErrorNotificationAction(
      errorResponse
    );

    const message =
      errorResponse && errorResponse.errors && errorResponse.errors.length > 0
        ? errorResponse.errors[0]
        : errorResponse.errors;
    const notification = {
      message: message,
      actionLabel: notificationAction.label,
      orientation: PoToasterOrientation.Top,
    };

    if (status === 400) {
      this.notification.warning(notification);
    } else {
      this.notification.error(notification);
    }
  }

  protected generateErrorNotificationAction(errorResponse: any) {
    let notificationAction;
    let notificationLabel;

    let notificationMessage = errorResponse.errors.concat(
      ` ${errorResponse.detailedMessage}`
    );

    if (errorResponse.details && errorResponse.details instanceof Array) {
      errorResponse.details.forEach((detailError: any) => {
        notificationMessage += `\n${detailError.message}`;
      });
    }

    if (
      errorResponse.helpUrl &&
      !(errorResponse.detailedMessage || errorResponse.details)
    ) {
      notificationLabel = 'Ajuda';
      notificationAction = this.generateUrlHelpFunction(errorResponse.helpUrl);
    } else if (errorResponse.detailedMessage || errorResponse.details) {
      notificationLabel = 'Detalhes';
      notificationAction = this.generateDialogDetailFunction(
        errorResponse,
        notificationMessage
      );
    }
    return { label: notificationLabel, action: notificationAction };
  }

  private generateUrlHelpFunction(helpUrl: string) {
    return () => {
      window.open(helpUrl, '_blank');
    };
  }

  private generateDialogDetailFunction(
    errorResponse: any,
    notificationMessage: string
  ) {
    return () => {
      this.dialog.alert({
        title: errorResponse.code,
        message: notificationMessage,
        ok: errorResponse.helpUrl
          ? this.generateUrlHelpFunction(errorResponse.helpUrl)
          : undefined,
      });
    };
  }
}
