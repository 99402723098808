import { Component, OnInit } from '@angular/core';
import {
  PoMenuItem,
  PoNavbarIconAction,
  PoNavbarItem,
} from '@po-ui/ng-components';
import { AuthService } from '../services/auth.service';
import { PoNavbarLiterals } from '@po-ui/ng-components/lib/components/po-navbar/interfaces/po-navbar-literals.interface';
import { poNavbarLiteralsDefault } from '@po-ui/ng-components/lib/components/po-navbar/po-navbar-base.component';
import { ReactiveFormsModule } from '@angular/forms';
import { convertImageToBase64 } from '@po-ui/ng-components/lib/utils/util';
import { HttpClient } from '@angular/common/http';
import { toBase64String } from '@angular/compiler/src/output/source_map';

@Component({
  selector: 'ses-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
})
export class MainContainerComponent {
  public readonly botoes: Array<PoNavbarIconAction> = [
    {
      label: 'Sair',
      icon: 'po-icon po-icon-exit',
      action: () => {
        this.authService.clearToken();
        window.location.href = '/';
      },
    },
  ];

  public readonly items: Array<PoNavbarItem> = [
    {
      label: `<img src="/assets/images/Logo-agenda-2.png" />`,
    },
  ];

  public readonly menus: Array<PoMenuItem> = [
    { label: 'Home', icon: 'po-icon-home', shortLabel: 'Home', link: '/' },
    /*{
      label: 'Guichê',
      icon: 'po-icon-device-desktop',
      shortLabel: 'Guichê',
      link: '/guiche',
    },*/
    {
      label: 'Minha Conta',
      icon: 'po-icon-user',
      shortLabel: 'Minha Conta',
      link: '/conta',
    },
    {
      label: 'Paciente',
      icon: 'po-icon-user',
      shortLabel: 'Paciente',
      link: '/paciente',
    },
    //{ label: 'Responsável', icon: 'po-icon-users', shortLabel: 'Responsável', link: '/responsavel'},
    {
      label: 'Unidade',
      icon: 'po-icon-company',
      shortLabel: 'Unidade',
      link: '/unidade',
    },
    {
      label: 'Tipo de Agenda',
      icon: 'po-icon-grid',
      shortLabel: 'Tipo',
      link: '/tipo',
    },
    {
      label: 'Configurar Agenda',
      icon: 'po-icon-plus-circle',
      shortLabel: 'Criar',
      link: '/configuracoes',
    },
    {
      label: 'Feriados',
      icon: 'po-icon-clear-content',
      shortLabel: 'Feriados',
      link: '/feriado',
    },
    {
      label: 'Agenda',
      icon: 'po-icon-calendar',
      shortLabel: 'Agenda',
      link: '/agenda',
    },
    {
      label: 'Atendimento',
      icon: 'po-icon-ok',
      shortLabel: 'Atendimento',
      subItems: [
        {
          label: 'Agendados',
          shortLabel: 'Agendados',
          link: '/atendimento/agendados',
        },
        {
          label: 'Em espera',
          shortLabel: 'Em espera',
          link: '/atendimento/espera',
        },
        {
          label: 'Chamados',
          shortLabel: 'Chamados',
          link: '/atendimento/chamados',
        },
        {
          label: 'Atendidos',
          shortLabel: 'Atendidos',
          link: '/atendimento/atendidos',
        },
      ],
    },
    {
      label: 'Cadastro de Usuários',
      icon: 'po-icon-user',
      shortLabel: 'Usuários',
      link: '/usuario',
    },
  ];

  constructor(private authService: AuthService, private http: HttpClient) {}
}
