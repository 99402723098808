// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: `${window.location.origin
    .replace('https://agendamedicamento-hml.saude.rj.gov.br', 'https://agendamedicamento-hml.saude.rj.gov.br:5003')
    .replace('http://agendamedicamento-hml.saude.rj.gov.br', 'http://agendamedicamento-hml.saude.rj.gov.br:81')
    .replace('4040', '5003')
    .replace('4200', '5000')
    .replace('80', '81')
    .replace('443', '5003')}/agendamedicamento/api/v1`,
  tipoAgenda: {
    profissionalSaude: '0af52923-b462-4171-9e24-ba05b864b69f',
    profissionalSeguranca: '217d9f57-eee1-4ce5-8307-466b04290af5',
    publicoGeral: 'cabd7050-c394-44c9-a96c-f910407e3b08',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
