import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localePt from '@angular/common/locales/pt';

import { AppComponent } from './app.component';
import { PoModule } from '@po-ui/ng-components';
import {
  PoModalPasswordRecoveryModule,
  PoPageLoginModule,
} from '@po-ui/ng-templates';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiPrefixInterceptor } from './shared/services/api-prefix.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { DataFormatterInterceptor } from './shared/services/data-formatter.interceptor';
import { registerLocaleData } from '@angular/common';
import { ErrorHandlerInterceptor } from './shared/services/error-handler.interceptor';
import { LoginModule } from './login/login.module';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { MainContainerComponent } from './core/main-container/main-container.component';

@NgModule({
  declarations: [AppComponent, MainContainerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PoModule,
    PoPageLoginModule,
    PoModalPasswordRecoveryModule,
    FormsModule,
    LoginModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataFormatterInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localePt);
