import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly STORAGE_KEY = 'token';

  constructor() {}

  setToken(token: string) {
    localStorage.setItem(this.STORAGE_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(this.STORAGE_KEY);
  }

  clearToken(): void {
    return localStorage.removeItem(this.STORAGE_KEY);
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(this.STORAGE_KEY);
  }
}
